import { onMounted, reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { emailValid, loginValid, nameValid, passwordValid, requiredValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { useRouter } from 'vue-router'
import { helpers } from '@vuelidate/validators'
import { createUserFromAdmin } from '@/http/user/userApi'
import { Alert } from '@/extension/Alert/Alert'

export function useCreateUserForm (filesController) {
  const router = useRouter()
  const startRole = new Map([
    ['admins', 1],
    ['user', 2],
    ['members-tp', 3],
    ['security', 4],
    ['organizer', 5]
  ])

  const nameRoleInAlert = new Map([
    [1, 'Администратор'],
    [2, 'Пользователь'],
    [3, 'Участник'],
    [4, 'Служба безопасности'],
    [5, 'Организатор']
  ])

  onMounted(() => {
    formData.roles = startRole.get(router.options.history.state?.back.split('/')[3])
  })

  const formData = reactive({
    active: false,
    name: '',
    surname: '',
    lastname: '',
    roles: null,
    login: '',
    email: '',
    password: '',
    confirmPassword: '',
    files: null
  })

  const rules = {
    password: {
      ...passwordValid,
      equalPassword: helpers.withMessage('Пароли не совпадают', () => formData.confirmPassword === formData.password)
    },
    confirmPassword: {
      ...passwordValid,
      equalPassword: helpers.withMessage('Пароли не совпадают', () => formData.confirmPassword === formData.password)
    },
    login: loginValid,
    name: nameValid,
    // surname: nameValid,
    // lastname: nameValid,
    roles: requiredValid,
    email: emailValid,
    // files: requiredValid
  }

  const formAction = async () => {
    const data = new FormData()
    const filesConfiguration = { data, setting: { files: formData.files, nameList: null } }
    const response = await createUserFromAdmin(formData)
    const userId = response.data.id
    const role = nameRoleInAlert.get(formData.roles)

    if (!userId) {
      return await Alert.show('error', 'Некорректный ответ с сервера')
    }

    await filesController.sendFiles(data, data.id, { formData, role, filesConfiguration })
    const msg = `Пользователь с ролью "${role}" был успешно создан`
    await router.push({ name: 'admin-users-all' })
    await Alert.show('success', msg)
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors, false, true),
    serverErrors: form.serverErrors
  }
}
