<template>
  <div class="create-user">
      <div class="create-user__main">
        <div class="block__subtitle mt-60">Участник</div>

        <form class="form" @submit.prevent="onSubmit">
          <div class="form__inner">
            <div class="block__subtitle-small mt-40 mb-40">Основная информация</div>

            <VCheckbox
              id="active"
              name="active"
              class-form="form-checkbox mb-20"
              class-label="bg-white"
              is-switch
              v-model="formData.active"
              @input="setValueCheckbox"
            >
              <template #default="slotProps">
                <label :for="slotProps.for" :class="slotProps.class">
                  {{ getValueCheckbox }}
                </label>
              </template>
            </VCheckbox>

            <div class="form__flex-start form__flex">
              <div class="form__left">
                <VInput
                  v-if="false"
                  id="surname"
                  name="surname"
                  placeholder="Фамилия*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.surname"
                  :errors="v$.surname.$errors"
                  @blur="validateField"
                  @input="validateField"
                />

                <VInput
                  id="name"
                  name="name"
                  placeholder="Контактное лицо ФИО*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.name"
                  :errors="v$.name.$errors"
                  :server-errors="serverErrors.value?.name"
                  @blur="validateField"
                  @input="validateField"
                />

                <VInput
                  v-if="false"
                  id="lastname"
                  name="lastname"
                  placeholder="Отчество*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.lastname"
                  :errors="v$.lastname.$errors"
                  @blur="validateField"
                  @input="validateField"
                />

                <VSelect
                  classes="select-border-bottom"
                  id="roles"
                  name="roles"
                  placeholder="Статус"
                  :options="roles"
                  v-model="formData.roles"
                  :errors="v$.roles.$errors"
                  :server-errors="serverErrors.value?.roles"
                />

                <VInput
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Новый пароль*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.password"
                  :errors="v$.password.$errors"
                  :server-errors="serverErrors.value?.password"
                  @blur="validateField"
                  @input="validateField"
                />
              </div>

              <div class="form__right">
                <VInput
                  id="login"
                  name="login"
                  placeholder="Логин (мин. 3 символа)*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.login"
                  :errors="v$.login.$errors"
                  :server-errors="serverErrors.value?.login"
                  @blur="validateField"
                  @input="validateField"
                />

                <VInput
                  name="email"
                  id="email"
                  placeholder="Email*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.email"
                  :errors="v$.email.$errors"
                  :server-errors="serverErrors.value?.email"
                  @blur="validateField"
                  @input="validateField"
                />

                <VInput
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  placeholder="Подтверждение нового пароля*"
                  :class-input="['input input-placeholder-black']"
                  v-model="formData.confirmPassword"
                  :errors="v$.confirmPassword.$errors"
                  :server-errors="serverErrors.value?.confirmPassword"
                  @blur="validateField"
                  @input="validateField"
                />
              </div>
            </div>

            <div class="form__subtitle mt-40 mb-40" v-if="false">Сопроводительная документация</div>

            <VFile
              v-if="false"
              id="files"
              name="files"
              multi-file
              v-model="formData.files"
              :errors="v$.files.$errors"
            />

            <div class="form__buttons form__buttons-create-user">
              <VButtonLoading
                :is-disabled="isSend"
                :is-errors="v$.$error"
                color="green"
              >
                Создать
              </VButtonLoading>
              <button
                class="btn btn-primary btn-bg-transparent"
                type="button"
                @click="toBack"
              >
                Отмена
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import { computed, ref, reactive, inject } from 'vue'
import VInput from '@/components/ui/form/VInput'
import VSelect from '@/components/ui/form/VSelect'
import VFile from '@/components/ui/form/File/VFile'
import { useCreateUserForm } from '@/use/Form/Admin/useCreateUserForm'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'
import { FILES_CONTROLLER } from '@/utils/consts'

export default {
  setup () {
    const router = useRouter()
    const valueCheckbox = ref(false)
    const filesController = inject(FILES_CONTROLLER)

    const roles = reactive([
      {
        name: 'Администратор',
        id: 1
      },
      {
        name: 'Пользователь',
        id: 2
      },
      {
        name: 'Участник',
        id: 3
      },
      {
        name: 'Организатор',
        id: 5
      },
      {
        name: 'Служба безопасности',
        id: 4
      }
    ])

    const setValueCheckbox = () => {
      valueCheckbox.value = !valueCheckbox.value
    }

    const getValueCheckbox = computed(() => {
      if (valueCheckbox.value) {
        return 'Активно'
      } else {
        return 'Не активно'
      }
    })

    const toBack = () => {
      return router.push({ name: 'admin-users-all' })
    }
    return {
      toBack,
      setValueCheckbox,
      roles,
      getValueCheckbox,
      ...useCreateUserForm(filesController)
    }
  },
  components: {
    VInput,
    VCheckbox,
    VSelect,
    VFile,
    VButtonLoading
  }
}
</script>
